import { loginRequest } from "../authConfig/authConfig";

// This function is used to get the token from MSAL
export const getMSALToken = async (instance, account, setAuthToken) => {
  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer

  const accessTokenRequest = {
    ...loginRequest,
    account: account,
  };

  // Use the same publicClientApplication instance provided to MsalProvider
  try {
    const accessTokenResponse = await instance.acquireTokenSilent(
      accessTokenRequest
    );
    let accessToken = accessTokenResponse.accessToken;
    setAuthToken(accessToken);
    return accessTokenResponse;
  } catch (e) {
    console.log(e);
    return null;
  }
};
