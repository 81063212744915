import { userServiceApiSlice } from "state/services/userServiceApiSlice";
import { adRequestStatusMap } from "utils/constants";
import { generateRandomColor } from "utils/generateRandomColor";
import { moveOrgArrayElement } from "utils/moveOrgArrayElement";

// The `userServiceApiQueries` object is used to generate hooks that can be used to make API calls to the user service.
export const userServiceApiQueries = userServiceApiSlice.injectEndpoints({
  // The `endpoints` object is used to define the API endpoints for the user service.
  endpoints: (builder) => ({
    // The `getUser` query is used to retrieve a user entry by email.
    getUser: builder.query({
      query: ({ email }) => ({
        url: `/users?email=${email}`,
      }),
      transformResponse: (responseData) => {
        return responseData.users;
      },
      providesTags: [{ type: "User", id: "Entry" }],
    }),
    // The `getUserWithOrgMembershipStatuses` query is used to retrieve a user with their organization membership statuses.
    getUserWithOrgMembershipStatuses: builder.query({
      query: ({ userId, platformId }) => ({
        url: `/users/${userId}/organization/membership?platform_id=${platformId}`,
      }),
      transformResponse: (responseData, meta, { userId, platformId }) => {
        // The `orgsListFormatted` array is used to store the formatted organization membership statuses for the user.
        try {
          const email = responseData.email;
          const orgsListWithUserStatus =
            responseData.platforms[platformId].organizations;

          // Split orgs into two arrays: joined/recommended and non-recommended
          let orgs = [{}, {}];

          // Add orgs with "Joined" status to orgs object
          for (const orgId in orgsListWithUserStatus) {
            if (orgsListWithUserStatus[orgId].membership_status === "Joined") {
              orgs[0][orgId] = orgsListWithUserStatus[orgId];
            }
          }

          // Add recommended orgs to orgs object
          for (const orgId in orgsListWithUserStatus) {
            if (
              orgsListWithUserStatus[orgId].recommended === true &&
              orgsListWithUserStatus[orgId].membership_status !== "Joined"
            ) {
              orgs[0][orgId] = orgsListWithUserStatus[orgId];
            }
          }

          // Add non-recommended orgs to orgs object
          for (const orgId in orgsListWithUserStatus) {
            if (
              orgsListWithUserStatus[orgId].recommended === false &&
              orgsListWithUserStatus[orgId].membership_status !== "Joined"
            ) {
              orgs[1][orgId] = orgsListWithUserStatus[orgId];
            }
          }

          // Format orgs object into orgsListFormatted array, maintaining split between joined/recommended and non-recommended orgs
          const orgsListFormatted = [[], []];
          let i = 1;

          for (const orgId in orgs[0]) {
            if (
              orgsListWithUserStatus[orgId].visible === true || // Modification
              orgsListWithUserStatus[orgId].user_type === "Owner" || // Modification
              orgsListWithUserStatus[orgId].user_type === "Approver" // Modification
            ) {
              orgsListFormatted[0].push({
                s_no: i++,
                id: orgId,
                orgDisplayName: orgsListWithUserStatus[orgId].org_name.replace(
                  /-/g,
                  " "
                ),
                orgName: orgsListWithUserStatus[orgId].org_name,
                status:
                  adRequestStatusMap[
                  orgsListWithUserStatus[orgId].membership_status
                  ],
                action:
                  adRequestStatusMap[
                    orgsListWithUserStatus[orgId].membership_status
                  ] === "Non-Member"
                    ? "Add"
                    : adRequestStatusMap[
                      orgsListWithUserStatus[orgId].membership_status
                    ] === "Failed"
                      ? "Add"
                      : "Delete",
                email: email,
                approvalRequired:
                  orgsListWithUserStatus[orgId]?.approval_required === true
                    ? "Yes"
                    : "No",
                userType: orgsListWithUserStatus[orgId]?.user_type,
                //userType: "Owner",
                signup_banner: orgsListWithUserStatus[orgId]?.signup_banner
                  ? orgsListWithUserStatus[orgId].signup_banner
                  : "No description available",
                membershipADGroup:
                  orgsListWithUserStatus[orgId]?.membership_ad_group,
                userId: userId,
                platformId: platformId,
                recommended: orgsListWithUserStatus[orgId]?.recommended,
              });
            }
          }

          for (const orgId in orgs[1]) {
            if (
              orgsListWithUserStatus[orgId].visible === true || // Modification
              orgsListWithUserStatus[orgId].user_type === "Owner" || // Modification
              orgsListWithUserStatus[orgId].user_type === "Approver" // Modification
            ) {
              orgsListFormatted[1].push({
                s_no: i++,
                id: orgId,
                orgDisplayName: orgsListWithUserStatus[orgId].org_name.replace(
                  /-/g,
                  " "
                ),
                orgName: orgsListWithUserStatus[orgId].org_name,
                status:
                  adRequestStatusMap[
                  orgsListWithUserStatus[orgId].membership_status
                  ],
                action:
                  adRequestStatusMap[
                    orgsListWithUserStatus[orgId].membership_status
                  ] === "Non-Member"
                    ? "Add"
                    : adRequestStatusMap[
                      orgsListWithUserStatus[orgId].membership_status
                    ] === "Failed"
                      ? "Add"
                      : "Delete",
                email: email,
                approvalRequired:
                  orgsListWithUserStatus[orgId]?.approval_required === true
                    ? "Yes"
                    : "No",
                userType: orgsListWithUserStatus[orgId]?.user_type,
                //userType: "Owner",
                signup_banner: orgsListWithUserStatus[orgId]?.signup_banner
                  ? orgsListWithUserStatus[orgId].signup_banner
                  : "No description available",
                membershipADGroup:
                  orgsListWithUserStatus[orgId]?.membership_ad_group,
                userId: userId,
                platformId: platformId,
                recommended: orgsListWithUserStatus[orgId]?.recommended,
              });
            }
          }

          return orgsListFormatted;
        } catch (error) {
          console.log(error);
          return error;
        }
      },
      providesTags: [{ type: "User", id: "OrgStatuses" }],
    }),
    // The `getUsersByOrgAndStatus` query is used to retrieve users by organization and status.
    getUsersByOrgAndStatus: builder.query({
      query: ({ orgId, status, platformId, page, perPage, email }) => {
        let url = `/users?org_id=${orgId}&membership_status=${status}`;

        if (page && perPage) {
          url += `&page=${page}&per_page=${perPage}`;
        }

        if (email) {
          url += `&email=${email}`;
        }

        return { url };
      },
      transformResponse: (
        responseData,
        meta,
        { orgId, platformId, status }
      ) => {
        try {
          const formattedOrgRequests = {
            total: responseData?.total,
            users: [],
          };

          for (const request of responseData.users) {
            const userRequestForOrg =
              request.platforms[platformId].organizations[orgId];

            const date = new Date(
              userRequestForOrg?.status_history[
                userRequestForOrg?.status_history.length - 1
              ]?.changed_date
            );

            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            };

            const formattedDate = date.toLocaleDateString("en-US", options);

            let fullName = "";
            if (request?.last_name) {
              fullName += `${request?.last_name}, `;
            }
            if (request?.first_name) {
              fullName += `${request?.first_name}`;
            }

            formattedOrgRequests.users.push({
              id: request._id,
              name: fullName,
              firstName: request?.first_name,
              lastName: request?.last_name,
              reason: userRequestForOrg?.reason,
              date: formattedDate,
              costCenter: request.platforms[platformId]?.cost_center_id || "",
              action:
                status === "Pending"
                  ? "approve"
                  : status === "Joined"
                    ? "remove"
                    : status === "Rejected"
                      ? "reset"
                      : status === "Remove-Requested"
                        ? "remove-requested"
                        : status === "Removed"
                          ? "removed"
                          : "",
              status: status.toLowerCase(),
              email: request.email,
              // costCenter: "Cost Center 1",
              // changedBy:
              //   userRequestForOrg?.status_history[
              //     userRequestForOrg?.status_history?.length - 1
              //   ]?.changed_by,
              changedBy: (() => {
                // Filter for entries with status "Pending"
                const pendingEntries = userRequestForOrg?.status_history?.filter(
                  (entry) => entry.status === "Pending"
                );
                
                // Find the latest "Pending" entry based on `changed_date`
                const latestPending = pendingEntries?.reduce((latest, current) => {
                return new Date(current.changed_date) > new Date(latest.changed_date)
                ? current
                : latest;
                }, pendingEntries[0]);

                // Return `changed_by` of the latest "Pending" entry or fallback to the last entry's `changed_by`
                return latestPending
                  ? latestPending.changed_by
                  : userRequestForOrg?.status_history?.[userRequestForOrg?.status_history.length - 1]?.changed_by;
              })(),
              color: generateRandomColor(request?._id),
            });
          }

          return formattedOrgRequests;
        } catch (err) {
          console.log(err);
          return err;
        }
      },
      providesTags: [{ type: "User", id: "OrgRequests" }],
    }),
    getCostCenterUsers: builder.query({
      query: () => ({
        url: `/users/costCenters`,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserWithOrgMembershipStatusesQuery,
  useLazyGetUserWithOrgMembershipStatusesQuery,
  useGetUsersByOrgAndStatusQuery,
  useLazyGetUsersByOrgAndStatusQuery,
  useGetCostCenterUsersQuery,
} = userServiceApiQueries;
